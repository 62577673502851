// src/data/filterTemplates.js

export const filterTemplates = [
    {
      name: "All",
      roles: [],
      domains: [],
      industries: []
    },
    {
      name: "Developer",
      roles: ["Developer"],
      domains: ["Web Development", "Backend", "Frontend"],
      industries: ["Software"]
    },
    {
      name: "Project Management",
      roles: ["Collaborator", "Team Lead"],
      domains: ["Project Management"],
      industries: ["Design"]
    },
    {
      name: "UI/UX Design",
      roles: ["Designer"],
      domains: ["UI/UX"],
      industries: ["Design", "Software"]
    },
    {
      name: "Data Analysis",
      roles: ["Analyst"],
      domains: ["Data Analysis"],
      industries: ["Data Science"]
    }
  ];
  