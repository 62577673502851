// src/components/Education.js
import React from 'react';

const Education = () => (
  <section className="resume-section">
    <h2>Education</h2>
    <div>
      <h3>Bachelor of Science in Computer Science</h3>
      <p>University of Example, 2013 - 2017</p>
    </div>
  </section>
);

export default Education;
