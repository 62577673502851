// src/components/Header.js
import React from 'react';

const Header = () => (
  <header className="resume-header">
    <h1>Gerard Noseworthy</h1>
    <p>Email: gnoseworthy@gmail.com | Phone: (709) 660-6292</p>
    <p>LinkedIn: linkedin.com/in/gerard-noseworthy-b14657135/ | GitHub: github.com/gwnoseworthy</p>
  </header>
);

export default Header;