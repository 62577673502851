// src/components/Home.js
import React from 'react';
import Resume from './Resume';

const Home = () => {
  return (
    <div>
      <Resume />
    </div>
  );
};

export default Home;