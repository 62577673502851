import React from 'react';

const Skills = ({ skills, selectedDomains }) => {
  console.log(skills)
  const filteredSkills = skills.filter(skill => 
    skill.domains.some(domain => selectedDomains.has(domain))
  );

  return (
    <section className="resume-section">
      <h2>Skills</h2>
      <ul>
        {filteredSkills.map((skill, index) => (
          <li key={index}>{skill.name}</li>
        ))}
      </ul>
    </section>
  );
};

export default Skills;