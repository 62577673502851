import React, { useState, useRef, useEffect } from 'react';
import './Resume.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Button, Layout, Drawer } from "antd";
import Header from './Header';
import Summary from './Summary';
import Experience from './Experience';
import Education from './Education';
import Skills from './Skills';
import FilterControls from './FilterControls';
import { experienceData } from '../data/experienceData';
import { skills } from '../data/skillsData';

const { Content, Sider } = Layout;

const Resume = () => {
  const roles = [...new Set(experienceData.flatMap(exp => exp.duties.flatMap(duty => duty.roles)))];
  const domains = [...new Set(experienceData.flatMap(exp => exp.duties.flatMap(duty => duty.domain)))];
  const industries = [...new Set(experienceData.flatMap(exp => exp.duties.flatMap(duty => duty.industry)))];
  const resumeRef = useRef(null);

  const [filters, setFilters] = useState({
    selectedRoles: new Set(roles),
    selectedDomains: new Set(domains),
    selectedIndustries: new Set(industries),
  });

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => setIsMobile(window.innerWidth <= 768);
    checkIsMobile(); // Initial check
    window.addEventListener('resize', checkIsMobile); // Listen for window resize events
    return () => {
      window.removeEventListener('resize', checkIsMobile); // Clean up the event listener
    };
  }, []);

  const handleFilterChange = (selectedRoles, selectedDomains, selectedIndustries) => {
    setFilters({ selectedRoles, selectedDomains, selectedIndustries });
  };

  const handleExportPDF = async () => {
    const resumeElement = resumeRef.current;
    const pages = resumeElement.querySelectorAll('.page');
    const pdf = new jsPDF('p', 'pt', 'a4');

    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];
      const canvas = await html2canvas(page, { scrollY: -window.scrollY });
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = 595.28;
      const pageHeight = 841.89;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      if (i > 0) {
        pdf.addPage();
      }
      while (heightLeft >= 0) {
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        position -= 841.89;
        if (heightLeft >= 0) {
          pdf.addPage();
        }
      }
    }

    pdf.save('resume.pdf');
  };

  const educations = [
    {
      degree: 'Bachelor of Science in Computer Science',
      institution: 'University of Example',
      date: '2013 - 2017',
    },
    // More education entries...
  ];


  return (
    <Layout>
      {isMobile ? (
        <Drawer
          width={300}
          title="Options"
          placement="left"
          closable={false}
          onClose={() => setDrawerVisible(false)}
          visible={drawerVisible}
          className="drawer"
        >
          <div className="sidebar">
            <Button type="primary" onClick={handleExportPDF}>Export as PDF</Button>
            <FilterControls
              roles={roles}
              domains={domains}
              industries={industries}
              onFilterChange={handleFilterChange}
            />
          </div>
        </Drawer>
      ) : (
        <Sider width={300} style={{ background: '#fff' }}>
          <div className="sidebar">
            <Button type="primary" onClick={handleExportPDF}>Export as PDF</Button>
            <FilterControls
              roles={roles}
              domains={domains}
              industries={industries}
              onFilterChange={handleFilterChange}
            />
          </div>
        </Sider>
      )}
      <Content>
        <div ref={resumeRef}>
          <div id="resume" className="resume">
            <div className="page">
              <Header />
              <Summary />
              <Experience experiences={experienceData} filters={filters} />
              <Education educations={educations} />
              <Skills skills={skills} selectedDomains={filters.selectedDomains} />
 />
            </div>
          </div>
        </div>
      </Content>
      {isMobile && (
        <Button
          type="primary"
          className="drawer-toggle"
          style={{ position: 'fixed', bottom: 20, right: 20, zIndex: 999 }}
          onClick={() => setDrawerVisible(!drawerVisible)}
        >
          Open Options
        </Button>
      )}
    </Layout>
  );
};

export default Resume;
