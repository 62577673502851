// src/components/Summary.js
import React from 'react';

const Summary = () => (
  <section className="resume-section">
    <h2>Summary</h2>
    <p>
      Highly motivated software developer with 5 years of experience in developing scalable web applications and working with modern web technologies.
    </p>
  </section>
);

export default Summary;