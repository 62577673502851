// src/data/experienceData.js

export const experienceData = [
    {
      company: "Tech Innovators Inc.",
      titles: ["Software Engineer", "Senior Software Engineer"],
      start_date: new Date("2018-01-01"),
      end_date: new Date("2023-05-31"),
      duties: [
        {
          roles: ["Developer", "Team Lead"],
          domain: ["Web Development", "Backend"],
          description: "Led a team of developers to build scalable web applications.",
          industry: ["Technology", "Software"]
        },
        {
          roles: ["Developer"],
          domain: ["Frontend"],
          description: "Developed user interfaces with React and Redux.",
          industry: ["Technology"]
        },
        {
          roles: ["Collaborator"],
          domain: ["Project Management"],
          description: "Coordinated with cross-functional teams to deliver projects on time.",
          industry: ["Technology"]
        },
        {
          roles: ["Designer"],
          domain: ["UI/UX"],
          description: "Implemented responsive designs to improve user experience.",
          industry: ["Software", "Design"]
        },
        {
          roles: ["Optimizer"],
          domain: ["Performance"],
          description: "Optimized application performance and reduced load times by 30%.",
          industry: ["Technology", "Web Development"]
        },
        {
          roles: ["Mentor"],
          domain: ["Training"],
          description: "Mentored junior developers and conducted code reviews.",
          industry: ["Technology"]
        }
      ]
    },
    {
      company: "Web Solutions LLC",
      titles: ["Frontend Developer"],
      start_date: new Date("2016-06-01"),
      end_date: new Date("2017-12-31"),
      duties: [
        {
          roles: ["Developer"],
          domain: ["Frontend"],
          description: "Built responsive websites using HTML, CSS, and JavaScript.",
          industry: ["Web Development", "Design"]
        },
        {
          roles: ["Designer"],
          domain: ["UI/UX"],
          description: "Collaborated with designers to create intuitive interfaces.",
          industry: ["Design"]
        },
        {
          roles: ["Tester"],
          domain: ["Quality Assurance"],
          description: "Performed unit and integration testing to ensure quality.",
          industry: ["Web Development"]
        },
        {
          roles: ["Optimizer"],
          domain: ["Performance"],
          description: "Improved website load times and performance.",
          industry: ["Web Development"]
        },
        {
          roles: ["Contributor"],
          domain: ["Open Source"],
          description: "Contributed to open-source projects to enhance skills.",
          industry: ["Web Development", "Technology"]
        }
      ]
    },
    {
      company: "CloudTech Corp.",
      titles: ["Cloud Engineer"],
      start_date: new Date("2014-01-01"),
      end_date: new Date("2016-05-31"),
      duties: [
        {
          roles: ["Developer"],
          domain: ["Cloud Infrastructure"],
          description: "Designed and implemented cloud solutions on AWS.",
          industry: ["Cloud Computing"]
        },
        {
          roles: ["Admin"],
          domain: ["DevOps"],
          description: "Managed CI/CD pipelines to automate deployments.",
          industry: ["Technology"]
        },
        {
          roles: ["Collaborator"],
          domain: ["Project Management"],
          description: "Worked with stakeholders to define project requirements.",
          industry: ["Technology"]
        },
        {
          roles: ["Optimizer"],
          domain: ["Performance"],
          description: "Enhanced cloud resource utilization and cost efficiency.",
          industry: ["Cloud Computing"]
        },
        {
          roles: ["Trainer"],
          domain: ["Training"],
          description: "Conducted training sessions on cloud technologies.",
          industry: ["Technology"]
        },
        {
          roles: ["Support"],
          domain: ["Technical Support"],
          description: "Provided technical support and troubleshooting for cloud issues.",
          industry: ["Cloud Computing"]
        }
      ]
    },
    {
      company: "Data Insights Ltd.",
      titles: ["Data Analyst", "Senior Data Analyst"],
      start_date: new Date("2011-01-01"),
      end_date: new Date("2013-12-31"),
      duties: [
        {
          roles: ["Analyst"],
          domain: ["Data Analysis"],
          description: "Analyzed large datasets to provide actionable insights.",
          industry: ["Data Science"]
        },
        {
          roles: ["Developer"],
          domain: ["Data Visualization"],
          description: "Created interactive data visualizations using D3.js.",
          industry: ["Data Science"]
        },
        {
          roles: ["Collaborator"],
          domain: ["Business Intelligence"],
          description: "Worked with business teams to understand data needs.",
          industry: ["Business"]
        },
        {
          roles: ["Trainer"],
          domain: ["Training"],
          description: "Trained team members on data analysis techniques.",
          industry: ["Data Science"]
        },
        {
          roles: ["Optimizer"],
          domain: ["Performance"],
          description: "Optimized data processing pipelines for efficiency.",
          industry: ["Technology"]
        }
      ]
    },
    {
      company: "FinTech Solutions",
      titles: ["Backend Developer"],
      start_date: new Date("2008-01-01"),
      end_date: new Date("2010-12-31"),
      duties: [
        {
          roles: ["Developer"],
          domain: ["Backend"],
          description: "Developed and maintained backend services using Node.js.",
          industry: ["Finance"]
        },
        {
          roles: ["Collaborator"],
          domain: ["API Development"],
          description: "Worked with frontend teams to design RESTful APIs.",
          industry: ["Technology"]
        },
        {
          roles: ["Tester"],
          domain: ["Quality Assurance"],
          description: "Conducted testing and debugging of backend services.",
          industry: ["Technology"]
        },
        {
          roles: ["Optimizer"],
          domain: ["Performance"],
          description: "Improved backend performance and scalability.",
          industry: ["Finance", "Technology"]
        },
        {
          roles: ["Support"],
          domain: ["Technical Support"],
          description: "Provided support for production issues and incidents.",
          industry: ["Finance"]
        },
        {
          roles: ["Contributor"],
          domain: ["Documentation"],
          description: "Wrote documentation for backend services and APIs.",
          industry: ["Technology"]
        }
      ]
    }
  ];
  