import React, { useState } from 'react';
import { filterTemplates } from '../data/filterTemplates';
import { Select, Checkbox } from 'antd';
const { Option } = Select;

const FilterControls = ({ roles, domains, industries, onFilterChange }) => {
  const [selectedRoles, setSelectedRoles] = useState(new Set(roles));
  const [selectedDomains, setSelectedDomains] = useState(new Set(domains));
  const [selectedIndustries, setSelectedIndustries] = useState(new Set(industries));

  const handleRoleChange = (role) => {
    const newSelectedRoles = new Set(selectedRoles);
    if (newSelectedRoles.has(role)) {
      newSelectedRoles.delete(role);
    } else {
      newSelectedRoles.add(role);
    }
    setSelectedRoles(newSelectedRoles);
    onFilterChange(newSelectedRoles, selectedDomains, selectedIndustries);
  };

  const handleDomainChange = (domain) => {
    const newSelectedDomains = new Set(selectedDomains);
    if (newSelectedDomains.has(domain)) {
      newSelectedDomains.delete(domain);
    } else {
      newSelectedDomains.add(domain);
    }
    setSelectedDomains(newSelectedDomains);
    onFilterChange(selectedRoles, newSelectedDomains, selectedIndustries);
  };

  const handleIndustryChange = (industry) => {
    const newSelectedIndustries = new Set(selectedIndustries);
    if (newSelectedIndustries.has(industry)) {
      newSelectedIndustries.delete(industry);
    } else {
      newSelectedIndustries.add(industry);
    }
    setSelectedIndustries(newSelectedIndustries);
    onFilterChange(selectedRoles, selectedDomains, newSelectedIndustries);
  };

  const handleTemplateChange = (e) => {
    console.log(e)
    const template = filterTemplates.find(t => t.name === e);
    if (template) {
      const newSelectedRoles = new Set(template.roles.length > 0 ? template.roles : roles);
      const newSelectedDomains = new Set(template.domains.length > 0 ? template.domains : domains);
      const newSelectedIndustries = new Set(template.industries.length > 0 ? template.industries : industries);
      setSelectedRoles(newSelectedRoles);
      setSelectedDomains(newSelectedDomains);
      setSelectedIndustries(newSelectedIndustries);
      onFilterChange(newSelectedRoles, newSelectedDomains, newSelectedIndustries);
    }
  };

  return (
    <div className="filter-controls">
      <div className="filter-section">
        <label>
          Template:
          <Select defaultValue={filterTemplates[0].name} onChange={handleTemplateChange} style={{ width: '100%' }}>
            {filterTemplates.map((template, index) => (
              <Option key={index} value={template.name}>{template.name}</Option>
            ))}
          </Select>
        </label>
      </div>
      <div className="filter-section">
        <h3>Roles</h3>
        <div className="checkbox-grid">
          {roles.map((role, index) => (
            <Checkbox
              key={index}
              checked={selectedRoles.has(role)}
              onChange={() => handleRoleChange(role)}
            >
              {role}
            </Checkbox>
          ))}
        </div>
      </div>
      <div className="filter-section">
        <h3>Domains</h3>
        <div className="checkbox-grid">
          {domains.map((domain, index) => (
            <Checkbox
              key={index}
              checked={selectedDomains.has(domain)}
              onChange={() => handleDomainChange(domain)}
            >
              {domain}
            </Checkbox>
          ))}
        </div>
      </div>
      <div className="filter-section">
        <h3>Industries</h3>
        <div className="checkbox-grid">
          {industries.map((industry, index) => (
            <Checkbox
              key={index}
              checked={selectedIndustries.has(industry)}
              onChange={() => handleIndustryChange(industry)}
            >
              {industry}
            </Checkbox>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FilterControls;
