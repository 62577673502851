import React from 'react';
import { Card } from 'antd';

const Experience = ({ experiences, filters }) => {
  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const filteredExperiences = experiences.filter(exp => {
    return exp.duties.some(duty => {
      const matchRoles = duty.roles.some(role => filters.selectedRoles.has(role));
      const matchDomains = duty.domain.some(domain => filters.selectedDomains.has(domain));
      const matchIndustries = duty.industry.some(industry => filters.selectedIndustries.has(industry));
      return matchRoles || matchDomains || matchIndustries;
    });
  });

  const experienceChunks = chunkArray(filteredExperiences, 3);

  return (
    <section className="resume-section">
      <h2>Experience</h2>
      {experienceChunks.map((chunk, pageIndex) => (
        <div key={pageIndex}>
          {chunk.map((exp, index) => (
            <Card
              key={index}
              className="experience-card"
              title={
                <div>
                  <span className="company-name">{exp.company}</span>
                  <span className="date-range">({exp.start_date.toLocaleDateString()} - {exp.end_date ? exp.end_date.toLocaleDateString() : "Present"})</span>
                </div>
              }
              style={{ width: '100%' }}
            >
              <p>
                <strong>Job Titles:</strong> {exp.titles.join(', ')}
              </p>
              <ul>
                {exp.duties
                  .filter(duty =>
                    duty.roles.some(role => filters.selectedRoles.has(role)) ||
                    duty.domain.some(domain => filters.selectedDomains.has(domain))
                  )
                  .map((duty, idx) => (
                    <li key={idx}>
                      <p>{duty.description}</p>
                    </li>
                  ))}
              </ul>
            </Card>
          ))}
        </div>
      ))}
    </section>
  );
};

export default Experience;
