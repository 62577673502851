export   const skills = [
    { name: 'JavaScript', domains: ['Web Development', 'Frontend'] },
    { name: 'React', domains: ['Web Development', 'Frontend'] },
    { name: 'Node.js', domains: ['Web Development', 'Backend'] },
    { name: 'HTML', domains: ['Web Development', 'Frontend'] },
    { name: 'CSS', domains: ['Web Development', 'Frontend'] },
    { name: 'SASS', domains: ['Web Development', 'Frontend'] },
    { name: 'Git', domains: ['DevOps'] },
    { name: 'GitHub', domains: ['DevOps'] },
    { name: 'CI/CD', domains: ['DevOps'] },
    { name: 'RESTful APIs', domains: ['Web Development', 'Backend'] },
    { name: 'GraphQL', domains: ['Web Development', 'Backend'] },

  ];
